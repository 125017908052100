.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;
  
  gap: 1em;

  padding-left: 2em;
  padding-right: 2em;
  padding-bottom: 2em;

  margin: 1em;
  border-radius: 2rem;

  max-width: 50em;

  background: linear-gradient(230deg, rgb(25, 37, 55) 0%, rgb(8, 19, 38) 100%);
}
.App h1 {
  color: #b7ea85;
}
.App h2 {
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

button {
  padding: 0.5em;
  font-size: 1.5em;
  background-color: #289f5e;
  color: white;
  border: none;
  border-radius: 1rem;
  margin-bottom: 1em;
  font-family: inherit;
  font-weight: bold;

  box-shadow: inset 0 -4px 0 0 rgba(34,41,47,.2);

  cursor: pointer;

  transition: 0.2s;
}
button:hover {
  background-color: #1b7e48;
}
button:active {
  background-color: #176b3d;
  box-shadow: inset 0 -1px 0 0 rgba(34,41,47,.2);
  transform: translateY(1px);
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.certik-logo {
  width: 50%;
  max-width: 17.5em;

  padding: 5px;

  background: #fff;
  border-radius: 1rem;

  cursor: pointer;
}
